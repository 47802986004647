export const themes = {
  light: {
    text1: "black",
    text2: "rgba(0,0,0,0.7)",
    backgroundColor: "#F2F6FF",
  },
  dark: {
    text1: "white",
    text2: "rgba(255,255,255,0.7)",
    backgroundColor: "#000",
  },
}
