import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { themes } from "../../styles/ColorStyles"
import { H4, Paragraph } from "../../styles/TextStyles"
import { Link } from "gatsby"
import { CompanyData, servicesData, socialNetwork } from "../../data/FooterData"
interface FooterProps {
  company?: string
  services?: string
  socialMedia?: string
}

const date = new Date()
const newDate = date.getFullYear()
const Footer: FunctionComponent<FooterProps> = props => {
  const {
    company = "JOSHUA ORGANISATION",
    services = "Services",
    socialMedia = "Social Media",
  } = props
  return (
    <FooterWrapper>
      <ContentWrapper>
        <Company>
          <Title>{company}</Title>
          {CompanyData.map((item, index) => (
            <Link key={index} to={item.link}>
              {item.title}
            </Link>
          ))}
        </Company>
        <Services>
          <Title>{services}</Title>
          {servicesData.map((item, index) => (
            <Link key={index} to={item.link}>
              {item.title}
            </Link>
          ))}
        </Services>
        <SocialMedia>
          <Title>{socialMedia}</Title>
          {socialNetwork.map((item, index) => (
            <Link key={index} to={item.link}>
              {item.title}
            </Link>
          ))}
        </SocialMedia>
      </ContentWrapper>
      <Subtitle>
        Backgrounds made in Figma, site made in React and Typescript © {newDate}{" "}
        Copyright Joshua Organisation
      </Subtitle>
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 100%;
  padding: 200px 38px;
  background: ${themes.dark.backgroundColor};
  display: grid;
  gap: 120px;
  justify-content: space-around;
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  @media (max-width: 640px) {
    grid-template-columns: auto;
  }
  gap: 130px;
  a {
    text-decoration: none;
    font-size: 14px;
    color: ${themes.dark.text2};
    :hover {
      color: #5bccf6;
    }
  }
`
const Company = styled.div`
  line-height: 188%;
  display: grid;
`
const Services = styled.div`
  line-height: 188%;
  display: grid;
`
const SocialMedia = styled.div`
  line-height: 188%;
  display: grid;
`
const Title = styled(H4)`
  color: ${themes.dark.text1};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
const Subtitle = styled(Paragraph)`
  color: ${themes.dark.text2};
  font-size: 15px;
  @media (max-width: 640px) {
    line-height: 130%;
  }
`
