import { Link } from "gatsby"
import React, { useState } from "react"
import { FunctionComponent } from "react"
import styled from "styled-components"
import { CompanyData } from "../../data/FooterData"
import { navigation } from "../../data/HeaderData"

interface mobileProps {}

const MobileMenu = () => {
  return (
    <MobileHero>
      <WrapperLinks>
        {navigation.map((link, index) => (
          <Link key={index} to={link.link}>
            {link.title}
          </Link>
        ))}
        {CompanyData.map((link, index) => (
          <Link key={index} to={link.link}>
            {link.title}
          </Link>
        ))}
      </WrapperLinks>
    </MobileHero>
  )
}
const Mobile: FunctionComponent<mobileProps> = () => {
  const [active, setActive] = useState(false)

  const handleClick = (e: React.FormEvent) => {
    setActive(!active)
    e.preventDefault()
  }

  return (
    <MobileM>
      <AmburgerButton onClick={handleClick}>
        {active ? "" : ""}
        <img
          alt=""
          src="https://image.flaticon.com/icons/png/512/17/17704.png"
        />
        {active && <MobileMenu />}
      </AmburgerButton>
    </MobileM>
  )
}

const AmburgerButton = styled.button`
  position: fixed;
  top: 5px;
  right: 0px;
  background: white;
  height: 50px;
  border-radius: 90px;
  border: none;
  outline: none;
  img {
    border-style: none;
    width: 30px;
    height: 30px;
  }

  @media (min-width: 769px) {
    display: none;
  }
  @media (min-width: 768px) {
    display: none;
  }
`

const MobileM = styled.div`
  margin: 0 auto;
`

const MobileHero = styled.div`
  position: absolute;
  right: 12px;
  top: 50px;
  width: 350px;
  background: #000;
  border-radius: 10px;

  a {
    color: white;
    text-decoration: none;
    font-family: "RobotoSlab", sans-serif;
    font-size: 18px;
    :hover {
      color: white;
    }
    border-top: thin;
  }
`
const WrapperLinks = styled.div`
  margin: 40px 0 20px 20px;
  display: grid;
  gap: 20px;
  justify-items: start;
`
export default Mobile
