export const navigation = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Maritime Training ",
    link: "/maritime",
  },
  {
    title: "Visa Application",
    link: "/visa-applications",
  },
  {
    title: "Company Registration",
    link: "/company-registration",
  },
  {
    title: "Accommodation",
    link: "/accomodation-services",
  },
  {
    title: "Tourism",
    link: "/tourism-services",
  },
  {
    title: "Support Overseas",
    link: "/overseas",
  },
]

export const companyNavigation = [
  {
    title: "Contact Us",
    link: "/contact",
  },
]
