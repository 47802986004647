import React, { FunctionComponent } from "react"
import {
  ContentWrapper,
  Image,
  LinkWrapper,
  Wrapper,
  AboutWrapper,
} from "../../styles/HeaderElement"
import { Link } from "gatsby"
import { companyNavigation, navigation } from "../../data/HeaderData"
import Mobile from "./Mobile"
interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Link to="/">
          <Image src="/assets/logos/logo.png" />
        </Link>
        <AboutWrapper>
          {navigation.map((item, index) => (
            <Link key={index} to={item.link}>
              {item.title}
            </Link>
          ))}
        </AboutWrapper>
        <LinkWrapper>
          {companyNavigation.map((item, index) => (
            <Link key={index} to={item.link}>
              {item.title}
            </Link>
          ))}
        </LinkWrapper>
      </ContentWrapper>
      <Mobile />
    </Wrapper>
  )
}

export default Header
