import styled from "styled-components"

export const Wrapper = styled.div`
  z-index: 1;
  padding: 30px 0 30px 0;
  position: fixed;
  top: 0px;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 30px 300px rgba(0, 0, 0, 0.18);
  width: 100%;
`
export const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 20px;

  @media (max-width: 640px) {
    display: none;
  }
  a {
    background: transparent;
    text-decoration: none;
    font-size: 15px;
    color: #000;
    font-weight: 350;
    :hover {
      color: #5bccf6;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`
export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  align-items: center;
  gap: 80px;
`

export const Image = styled.img`
  top: 10px;
  left: 80px;
  position: fixed;
  width: 40px;
  height: 50px;
  @media (max-width: 768px) {
    left: 20px;
  }

  @media (max-width: 640px) {
    left: 30px;
  }
`
export const AboutWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  justify-content: center;
  gap: 40px;

  a {
    background: transparent;
    text-decoration: none;
    font-size: 15px;
    color: #000;
    font-weight: 350;
    :hover {
      color: #5bccf6;
    }
    @media (max-width: 768px) {
      font-size: 12px;
    }
    @media (max-width: 640px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, auto);
    a:nth-child(2) {
      display: none;
    }
    a:nth-child(3) {
      display: none;
    }
    a:nth-child(4) {
      display: none;
    }
    a:nth-child(5) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, auto);
    a:nth-child(2) {
      display: none;
    }
    a:nth-child(3) {
      display: none;
    }
    a:nth-child(4) {
      display: none;
    }
    a:nth-child(5) {
      display: none;
    }
  }
`
