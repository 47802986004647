import React, { ReactNode } from "react"
import Header from "./Header"
import GlobalStyle from "../../styles/GlobalStyle"
import Footer from "./Footer"
interface LayoutProps {
  children?: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
