export const servicesData = [
  {
    title: "Maritime Training",
    link: "/",
  },
  {
    title: "Visa Application",
    link: "/visa-applications",
  },
  {
    title: "Company Registration",
    link: "/company-registration",
  },
  {
    title: "Tourism",
    link: "/tourism-services",
  },
  {
    title: "Accomodation",
    link: "/accomodation-services",
  },
]

export const CompanyData = [
  {
    title: "About Us",
    link: "/about",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
  {
    title: "Reviews",
    link: "/reviews",
  },
  {
    title: "Enquire",
    link: "/enquire",
  },
]

export const socialNetwork = [
  {
    title: "Facebook",
    link: "https://www.facebook.com/Joshuas-Organisation-2374424205925522/",
  },
  {
    title: "Instagram",
    link: "https://instagram.com/joshuasorganisation?utm_medium=copy_link",
  },
  {
    title: "Linkedin",
    link: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQG3sW6_VjcmOwAAAXzGPSdIXuoBk6ozQtMLtrWaK2wPtH4L1zx1ncI4xo2ugFgg69xhPkhrjGeFvb4N-WsgwQPLfONI-knGQD4U10AMR9dJfp52jPvFVv-Xt6PhXVrVgnWfQjU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fjoshua-organisation%2Fabout%2F",
  },
]
